import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SalesContext } from '../contexts/SalesContext';
import { Star, StarFill, Telephone, Archive, Trash, ArrowClockwise, CheckCircleFill, ClockFill, ExclamationTriangleFill, Robot } from 'react-bootstrap-icons';
import './SalesList.css';
import SalesSidebar from './SalesSidebar';

// Add the formatUtils object
const formatUtils = {
  duration: (seconds) => {
    if (!seconds) return '';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) return `${hours}h ${minutes}m`;
    if (minutes > 0) return `${minutes} mins`;
    return `${remainingSeconds} secs`;
  },

  dateTime: (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp._seconds * 1000);
    const now = new Date();
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

    if (date.toDateString() === now.toDateString()) return time;
    
    if (date.getFullYear() === now.getFullYear()) {
      return `${date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })} ${time}`;
    }

    return `${date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: '2-digit',
      year: 'numeric'
    })} ${time}`;
  },

  timestampClass: (timestamp) => {
    if (!timestamp) return 'timestamp';
    const date = new Date(timestamp._seconds * 1000);
    const now = new Date();
    
    if (date.toDateString() === now.toDateString()) return 'timestamp-today';
    if (date.getFullYear() === now.getFullYear()) return 'timestamp-this-year';
    return 'timestamp-other-year';
  },

  statusIcon: (status) => {
    if (!status) return null;
    
    const statusLower = status.toLowerCase();
    
    switch (true) {
      case statusLower === 'ready':
        return <CheckCircleFill className="status-icon status-ready" />;
      case statusLower === 'processing':
        return <ClockFill className="status-icon status-processing" />;
      case statusLower === 'transcribing':
        return <Robot className="status-icon status-transcribing" />;
      case statusLower === 'active':
        return <Telephone className="status-icon status-active" />;
      case statusLower.startsWith('error'):
        return <ExclamationTriangleFill className="status-icon status-error" />;
      default:
        return null;
    }
  }
};

// Add sub-components
const MeetingActions = ({ session, onSelect, isSelected, onStar, isStarred }) => (
  <div className="meeting-actions">
    <input
      type="checkbox"
      className="form-check-input"
      checked={isSelected}
      onChange={(e) => onSelect(e, session.id)}
      onClick={(e) => e.stopPropagation()}
    />
    <button
      className="btn btn-icon"
      onClick={(e) => onStar(e, session.id)}
    >
      {isStarred ?
        <StarFill className="star-icon filled" /> :
        <Star className="star-icon" />
      }
    </button>
  </div>
);

const HoverActions = ({ onArchive, onDelete, onRetry, sessionId, t }) => (
  <div className="hover-actions">
    <button
      className="btn btn-icon"
      onClick={(e) => onArchive(e, sessionId)}
      title={t('archive')}
    >
      <Archive />
    </button>
    <button
      className="btn btn-icon"
      onClick={(e) => onDelete(e, sessionId)}
      title={t('delete')}
      style={{ display: 'none' }}
    >
      <Trash />
    </button>
    <button
      className="btn btn-icon"
      onClick={(e) => onRetry(e, sessionId)}
      title={t('retry')}
    >
      <ArrowClockwise />
    </button>
  </div>
);

// Add MobileMeetingCard component
const MobileMeetingCard = ({ 
  session, 
  onSelect, 
  isSelected, 
  onStar, 
  isStarred, 
  onView,
  onArchive,
  onDelete,
  onRetry,
  t 
}) => (
  <div className="mobile-meeting-card">
    <div className="mobile-meeting-header">
      <div className="mobile-meeting-title">
        {formatUtils.statusIcon(session.status)}
        <span>[Meeting Title]</span>
      </div>
      <MeetingActions
        session={session}
        onSelect={onSelect}
        isSelected={isSelected}
        onStar={onStar}
        isStarred={isStarred}
      />
    </div>

    <div className="mobile-meeting-main">
      <div className="mobile-meeting-participants">
        <div className="mobile-participant">
          <span className="fw-medium">{session.sessionDestination}</span>
        </div>
        <div className="mobile-participant">
          <span>{session.sessionSource}</span>
        </div>
      </div>
      
      <div className="meeting-transcript text-muted">
        Lorem ipsum dolor sit amet, consectetur adipiscing elitaser...
      </div>
    </div>

    <div className="mobile-meeting-footer">
      <div className="mobile-meeting-stats">
        <span>{formatUtils.duration(session.sessionDuration)}</span>
        <span>{formatUtils.dateTime(session.createdAt)}</span>
      </div>
      
      <div className="mobile-meeting-actions">
        <button
          className="btn btn-icon"
          onClick={(e) => {
            e.stopPropagation();
            onView(session.id);
          }}
        >
          <i className="bi bi-eye"></i>
        </button>
        <button
          className="btn btn-icon"
          onClick={(e) => onArchive(e, session.id)}
        >
          <Archive />
        </button>
        <button
          className="btn btn-icon"
          onClick={(e) => onDelete(e, session.id)}
          style={{ display: 'none' }}
        >
          <Trash />
        </button>
        <button
          className="btn btn-icon"
          onClick={(e) => onRetry(e, session.id)}
        >
          <ArrowClockwise />
        </button>
      </div>
    </div>
  </div>
);

const SalesList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    sessions,
    loading,
    error,
    fetchSessions,
    deleteSession,
    archiveSession,
  } = useContext(SalesContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [starredSessions, setStarredSessions] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState(new Set());
  const [activeTab, setActiveTab] = useState('primary');

  useEffect(() => {
    fetchSessions(currentPage, searchTerm);
  }, [currentPage, searchTerm, fetchSessions]);

  const handleView = (id) => {
    navigate(`/sales/${id}`);
  };

  const handleSearchClick = () => {
    setSearchTerm(searchQuery);
    setCurrentPage(1);
  };

  const toggleStar = (e, sessionId) => {
    e.stopPropagation();
    setStarredSessions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sessionId)) {
        newSet.delete(sessionId);
      } else {
        newSet.add(sessionId);
      }
      return newSet;
    });
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedSessions(new Set(sessions.map(s => s.id)));
    } else {
      setSelectedSessions(new Set());
    }
  };

  const handleSelectSession = (e, sessionId) => {
    e.stopPropagation();
    setSelectedSessions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sessionId)) {
        newSet.delete(sessionId);
      } else {
        newSet.add(sessionId);
      }
      return newSet;
    });
  };

  const handleArchive = async (e, id) => {
    e.stopPropagation();
    if (window.confirm(t('confirmArchive'))) {
      await archiveSession(id);
    }
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    if (window.confirm(t('confirmDelete'))) {
      await deleteSession(id);
    }
  };

  return (
    <div id="SalesList" className="d-flex h-100">
      <div className="sales-content container-fluid p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2>{t('sales')}</h2>
            <p className="text-muted mb-0">{t('salesListDescription')}</p>
          </div>
        </div>

        <div className="meetings-container">
          {/* Controls */}
          <div className="meetings-controls d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
              <button className="btn btn-icon" onClick={() => fetchSessions(currentPage)}>
                <ArrowClockwise />
              </button>
            </div>

            <div className="pagination-info">
              <span className="text-muted">
                {`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, sessions.length)} of ${sessions.length}`}
              </span>
              <button 
                className="btn btn-icon"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(prev => prev - 1)}
              >
                ‹
              </button>
              <button
                className="btn btn-icon"
                disabled={sessions.length < pageSize}
                onClick={() => setCurrentPage(prev => prev + 1)}
              >
                ›
              </button>
            </div>
          </div>

          {/* Tabs */}
          <div className="meetings-tabs">
            <div className="tabs-container">
              <button 
                className={`tab-button ${activeTab === 'primary' ? 'active' : ''}`}
                onClick={() => setActiveTab('primary')}
              >
                {t('primary')}
              </button>
              <button 
                className={`tab-button ${activeTab === 'unanswered' ? 'active' : ''}`}
                onClick={() => setActiveTab('unanswered')}
              >
                {t('unanswered')}
              </button>
              <button 
                className={`tab-button ${activeTab === 'archived' ? 'active' : ''}`}
                onClick={() => setActiveTab('archived')}
              >
                {t('archived')}
              </button>
              <button 
                className={`tab-button ${activeTab === 'favorites' ? 'active' : ''}`}
                onClick={() => setActiveTab('favorites')}
              >
                {t('favorites')}
              </button>
            </div>
          </div>

          {/* List */}
          <div className="meetings-list">
            {loading ? (
              <div className="text-center py-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{t('loading')}</span>
                </div>
              </div>
            ) : (
              sessions.map((session) => (
                <div
                  key={session.id}
                  className="meeting-item"
                  onClick={() => handleView(session.id)}
                >
                  <div className="meeting-row">
                    <MeetingActions
                      session={session}
                      onSelect={handleSelectSession}
                      isSelected={selectedSessions.has(session.id)}
                      onStar={toggleStar}
                      isStarred={starredSessions.has(session.id)}
                    />

                    <div className="meeting-content">
                      <span className="recipient">{session.sessionDestination}</span>
                      <span className="creator">{session.sessionSource}</span>
                      <span className="meeting-name">
                        <span className="meeting-title">
                          {formatUtils.statusIcon(session.status)}
                          [Meeting Title]
                        </span>
                        <span className="meeting-transcript">
                          - Lorem ipsum dolor sit amet...
                        </span>
                      </span>
                      <span className="duration">{formatUtils.duration(session.sessionDuration)}</span>
                      <span className={formatUtils.timestampClass(session.createdAt)}>
                        {formatUtils.dateTime(session.createdAt)}
                      </span>

                      <HoverActions
                        onArchive={handleArchive}
                        onDelete={handleDelete}
                        onRetry={() => {}}
                        sessionId={session.id}
                        t={t}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Mobile List */}
          <div className="mobile-meetings-list">
            {loading ? (
              <div className="text-center py-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{t('loading')}</span>
                </div>
              </div>
            ) : (
              sessions.map((session) => (
                <MobileMeetingCard
                  key={session.id}
                  session={session}
                  onSelect={handleSelectSession}
                  isSelected={selectedSessions.has(session.id)}
                  onStar={toggleStar}
                  isStarred={starredSessions.has(session.id)}
                  onView={handleView}
                  onArchive={handleArchive}
                  onDelete={handleDelete}
                  onRetry={() => {}}
                  t={t}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <div className="sales-sidebar">
        <SalesSidebar />
      </div>
    </div>
  );
};

export default SalesList; 