import React, { createContext, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';

export const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promptCache, setPromptCache] = useState({});

  // Memoize the Axios instance
  const api = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${user?.token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [user?.token]);

  const fetchPrompts = useCallback(
    async (page = 1, search = '') => {
      const cacheKey = `${page}-${search}`;

      // Check if cached prompts exist and are less than 1 minute old
      if (
        promptCache[cacheKey] &&
        Date.now() - promptCache[cacheKey].timestamp < 1 * 60 * 1000
      ) {
        // Use cached prompts
        setPrompts(promptCache[cacheKey].data);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        if (!user?.token) {
          throw new Error('User not authenticated');
        }

        const response = await api.get('/prompts', {
          params: {
            page,
            search,
          },
        });
        const promptData = Array.isArray(response.data) ? response.data : [];
        setPrompts(promptData);

        // Update cache
        setPromptCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: {
            data: promptData,
            timestamp: Date.now(),
          },
        }));
      } catch (err) {
        console.error('Error fetching prompts:', err);
        setError(err.message || t('errorFetchingPrompts'));
        setPrompts([]);
      } finally {
        setLoading(false);
      }
    },
    [api, user?.token, t, promptCache]
  );

  const getPrompt = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      try {
        if (!user?.token) {
          throw new Error('User not authenticated');
        }

        const response = await api.get(`/prompts/${id}`);
        return response.data;
      } catch (err) {
        console.error('Error fetching prompt:', err);
        setError(err.message || t('errorFetchingPrompt'));
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [api, user?.token, t]
  );

  const createPrompt = async (promptData) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.post('/prompts', promptData);
      setPromptCache({}); // Invalidate the entire cache
      await fetchPrompts();
    } catch (err) {
      console.error('Error creating prompt:', err);
      setError(err.message || t('errorCreatingPrompt'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updatePrompt = async (id, promptData) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.put(`/prompts/${id}`, promptData);
      setPromptCache({}); // Invalidate the entire cache
      await fetchPrompts();
    } catch (err) {
      console.error('Error updating prompt:', err);
      setError(err.message || t('errorUpdatingPrompt'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deletePrompt = async (id) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.delete(`/prompts/${id}`);
      setPromptCache({}); // Invalidate the entire cache
      setPrompts((prevPrompts) => prevPrompts.filter((p) => p.id !== id));
    } catch (err) {
      console.error('Error deleting prompt:', err);
      setError(err.message || t('errorDeletingPrompt'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const archivePrompt = async (id) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.put(`/prompts/${id}`, { isArchived: true });

      setPromptCache({}); // Invalidate the cache
      await fetchPrompts();
    } catch (err) {
      console.error('Error archiving prompt:', err);
      setError(err.message || t('errorArchivingPrompt'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <PromptContext.Provider
      value={{
        prompts,
        loading,
        error,
        fetchPrompts,
        getPrompt,
        createPrompt,
        updatePrompt,
        deletePrompt,
        archivePrompt,
      }}
    >
      {children}
    </PromptContext.Provider>
  );
}; 