import React, { createContext, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';

export const SalesContext = createContext();

export const SalesProvider = ({ children }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionCache, setSessionCache] = useState({});

  // Memoize the Axios instance
  const api = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${user?.token}`,
        'Content-Type': 'application/json',
      },
    });
  }, [user?.token]);

  const fetchSessions = useCallback(
    async (page = 1, search = '') => {
      const cacheKey = `${page}-${search}`;

      // Check if cached sessions for this page and search term exist and are less than 1 minute old
      if (
        sessionCache[cacheKey] &&
        Date.now() - sessionCache[cacheKey].timestamp < 1 * 60 * 1000
      ) {
        // Use cached sessions
        setSessions(sessionCache[cacheKey].data);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        if (!user?.token) {
          throw new Error('User not authenticated');
        }

        const response = await api.get('/sessions', {
          params: {
            page,
            search,
            sessionType: 'sales'
          },
        });
        const sessionData = Array.isArray(response.data) ? response.data : [];
        setSessions(sessionData);

        // Update cache
        setSessionCache((prevCache) => ({
          ...prevCache,
          [cacheKey]: {
            data: sessionData,
            timestamp: Date.now(),
          },
        }));
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError(err.message || t('errorFetchingSessions'));
        setSessions([]);
      } finally {
        setLoading(false);
      }
    },
    [api, user?.token, t, sessionCache]
  );

  const getSession = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      try {
        if (!user?.token) {
          throw new Error('User not authenticated');
        }

        const response = await api.get(`/sessions/${id}`);
        return response.data;
      } catch (err) {
        console.error('Error fetching session:', err);
        setError(err.message || t('errorFetchingSession'));
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [api, user?.token, t]
  );

  const createSession = async (sessionData) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      const sessionWithType = {
        ...sessionData,
        sessionType: 'sales'
      };

      const response = await api.post('/sessions', sessionWithType);
      console.log('Server response:', response);
      const newSession = response.data;
      console.log('New session data:', newSession);

      setSessionCache({}); // Invalidate the entire cache
      await fetchSessions();

      const sessionId = newSession?.sessionId || newSession?.id;
      if (!sessionId) {
        console.error('Response structure:', newSession);
        throw new Error('No session ID returned from server');
      }
      
      return sessionId;
    } catch (err) {
      console.error('Error creating session:', err);
      if (err.response) {
        console.error('Server error response:', err.response.data);
      }
      setError(err.message || t('errorCreatingSession'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateSession = async (id, sessionData) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.put(`/sessions/${id}`, sessionData);
      setSessionCache({}); // Invalidate the entire cache
      await fetchSessions();
    } catch (err) {
      console.error('Error updating session:', err);
      setError(err.message || t('errorUpdatingSession'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteSession = async (id) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.delete(`/sessions/${id}`);
      setSessionCache({}); // Invalidate the entire cache
      setSessions((prevSessions) => prevSessions.filter((s) => s.id !== id));
    } catch (err) {
      console.error('Error deleting session:', err);
      setError(err.message || t('errorDeletingSession'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const archiveSession = async (id) => {
    setLoading(true);
    setError(null);
    try {
      if (!user?.token) {
        throw new Error('User not authenticated');
      }

      await api.put(`/sessions/${id}`, { archived: true });

      setSessionCache({}); // Invalidate the cache
      await fetchSessions();
    } catch (err) {
      console.error('Error archiving session:', err);
      setError(err.message || t('errorArchivingSession'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const retryTranscription = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/audio-transcribe', { sessionId: id });
      console.log('Retranscription scheduled:', response.data);
      // Optionally, update the session status locally if needed
    } catch (err) {
      console.error('Error retrying transcription:', err);
      setError(err.message || t('errorRetryingTranscription'));
    } finally {
      setLoading(false);
    }
  };

  const retryAnalysis = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/audio-analysis', { sessionId: id });
      console.log('Analysis retriggered:', response.data);
      // Optionally, update the session status locally if needed
    } catch (err) {
      console.error('Error retrying analysis:', err);
      setError(err.message || t('errorRetryingAnalysis'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <SalesContext.Provider
      value={{
        sessions,
        loading,
        error,
        fetchSessions,
        getSession,
        createSession,
        updateSession,
        deleteSession,
        archiveSession,
        retryTranscription,
        retryAnalysis,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
}; 